import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/app-movil',
    name: 'AppMovil',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppMovil.vue')
  },
  {
    path: '/app-movil-caracteristicas',
    name: 'AppMovilFeatures',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppMovilFeatures.vue')
  },
  {
    path: '/app-movil-detalle',
    name: 'AppMovilDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppMovilDetail.vue')
  },
  {
    path: '/como-funciona',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/productos',
    name: 'Products',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue')
  },
  {
    path: '/brain',
    name: 'Brain',
    component: () => import(/* webpackChunkName: "about" */ '../views/Brain.vue')
  },
  {
    path: '/brain-caracteristicas',
    name: 'BrainFeatures',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrainFeatures.vue')
  },
  {
    path: '/brain-detalle',
    name: 'BrainDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrainDetail.vue')
  },
  {
    path: '/contrato',
    name: 'Agreement',
    component: () => import(/* webpackChunkName: "about" */ '../views/Agreement.vue')
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Marketing.vue')
  },
  {
    path: '/marketing-detalle',
    name: 'MarketingDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/MarketingDetail.vue')
  },
  {
    path: '/marketing-facebook',
    name: 'MarketingFacebook',
    component: () => import(/* webpackChunkName: "about" */ '../views/MarketingFacebook.vue')
  },
  {
    path: '/marketing-google',
    name: 'MarketingGoogle',
    component: () => import(/* webpackChunkName: "about" */ '../views/MarketingGoogle.vue')
  },
  {
    path: '/marketing-instagram',
    name: 'MarketingInstagram',
    component: () => import(/* webpackChunkName: "about" */ '../views/MarketingInstagram.vue')
  },
  {
    path: '/messenger-bot',
    name: 'MessengerBot',
    component: () => import(/* webpackChunkName: "about" */ '../views/MessengerBot.vue')
  },
  {
    path: '/messenger-bot-caracteristicas',
    name: 'MessengerBotFeatures',
    component: () => import(/* webpackChunkName: "about" */ '../views/MessengerBotFeatures.vue')
  },
  {
    path: '/messenger-bot-detalle',
    name: 'MessengerBotDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/MessengerBotDetail.vue')
  },
  {
    path: '/web-responsive',
    name: 'WebResponsive',
    component: () => import(/* webpackChunkName: "about" */ '../views/WebResponsive.vue')
  },
  {
    path: '/web-responsive-detalle',
    name: 'WebResponsiveDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/WebResponsiveDetail.vue')
  },
  {
    path: '/web-responsive-caracteristicas',
    name: 'WebResponsiveFeatures',
    component: () => import(/* webpackChunkName: "about" */ '../views/WebResponsiveFeatures.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
